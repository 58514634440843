@tailwind base;
@tailwind components;
@tailwind utilities;



  
  body {
    /* background-color: #000300; */
    background-color: #F1F2F5;
  }
